<template>
	<div>
		<pop-up-window
			:show="showDeleteWindow"
			:additional-info="docNumber"
			:top0="true"
		>
			<template #title>
				<h1 class="text-2xl">{{ docNumber }}</h1>
			</template>
			<template #content>
				<div class="text-center mb-4">
					<i class="icon-notification warning text-popup mr-2"></i>
					<span class="text-popup">{{ $t('documentDetail.resend.deleteText') }}</span>
				</div>
				<div class="btn-content">
					<div>
						<button class="btn custom-button shadow-md mr-2 text-popup" @click="cancelDelete()">
							{{ $t('buttons.cancel') }}
						</button>
						<button class="btn custom-button shadow-md mr-2 text-popup" @click="deleteDocument">
							{{ $t('buttons.delete') }}
						</button>
					</div>
				</div>
			</template>
		</pop-up-window>
		<div
			:class="{'button-container-outer': this.$route.name !== 'public', 'download-buttons-public-page' : this.$route.name === 'public'}">
			<div class="buttons-left">
				<router-link :to=routerData.overview.link :class="{'displayNone' : isPublicPage}">
					<p id="goBack" style="text-align: center"></p>
				</router-link>
			</div>

			<div :class="displayDownloadOriginals ? 'button-container' : 'button-container-without-dropdown'">
				<div class="download-original" v-if="displayDownloadOriginals">
					<v-select
						:clearable=false
						v-model="downloadFile"
						:options='downloadFileTypeOptions'
					>
						<template #no-options="{search}">
							{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
						</template>
					</v-select>
				</div>

				<div class="buttons-right">
					<div v-if="this.$route.name !== 'public'" class="buttons-right">
						<button
							v-if="showResendButton" class="btn custom-button shadow-md mr-2 no-break-text"
							@click="showResendDocumentWindow"
							:disabled="disableResendButton"
						>
							{{ $t('buttons.resend') }}
							<span v-if="displayTooltipNoChannelsAreSet" class="tooltip">
								{{ $t('buttons.displayTooltipNoChannelsAreSet') }}
							</span>
							<span
								v-else-if="this.$store.state.documentDetailData.held === undefined || this.$store.state.documentDetailData.held === false"
								class="tooltip"
							>
								{{ $t('buttons.downloadTooltipOverdueOnboardingSelected') }}
							</span>
							<span v-else-if="this.$store.state.documentDetailData.held === true" class="tooltip">
								{{ $t('buttons.disableResendBecauseOfUnreleasedDocument') }}
							</span>

						</button>
						<drop-down-menu
							:main-button-name="$t('buttons.actions')"
							:width-px="widthMenuActions"
							v-if="showAddAttachmentButton || displayHandleErrors"
							class="mr-2"
						>
							<template v-slot:options>
								<button v-if="displayHandleErrors" @click="handleErrors">
									{{ $t('buttons.handleError') }}
								</button>
								<button v-if="showAddAttachmentButton" @click="showAddAttachment = true">
									{{ $t('buttons.addAttachment') }}
								</button>
								<button v-if="showHoldRelease" @click="showRelease = true">
									{{ $t('buttons.releaseDocument') }}
								</button>
								<button v-if="showVisibility" @click="showPopupVisibility = true">
									{{ toggleVisibilityText }}
								</button>
								<button v-if="showPaidButton" @click="showPopupPayment = true">
									{{ paidButtonText }}
								</button>
							</template>
						</drop-down-menu>

						<add-attachment
							:show="showAddAttachment"
							@released-document="showReleaseConfirmAlert"
							@close="showAddAttachment = false"
							@showResend="showResendDocument = true"
						/>
						<!--Confirm Payment-->
						<confirmation-with-props
							:description="descriptionConfirmDialogPaymentStatus"
							:show-dialog="showPopupPayment"
							@cancel-action="showPopupPayment = false"
							@approve-action="setPaymentStatus"
						/>
						<confirmation-with-props
							:description="descriptionConfirmDialogVisibility"
							:show-dialog="showPopupVisibility"
							@cancel-action="showPopupVisibility = false"
							@approve-action="toggleVisibility"
						/>
						<confirmation-with-props
							:description="descriptionConfirmDialogRelease"
							:warning="warningRelease"
							:show-dialog="showRelease"
							@cancel-action="showRelease = false"
							@approve-action="releaseDocument"
						/>
						<!--May take a while to update alert-->
						<alert-new
							:title="$t('alert.confirmPaymentStatusChanged.title')"
							:is-visible="showConfirmationChangedPayment"
							:text="$t('alert.confirmPaymentStatusChanged.text')"
							:position-higher="200"
						/>
						<alert-new
							:title="$t('alert.confirmVisibilityChanged.title')"
							:is-visible="showConfirmationChangedVisibility"
							:text="$t('alert.confirmVisibilityChanged.text')"
							:position-higher="200"
						/>
						<alert-new
							:title="$t('alert.confirmRelease.title')"
							:is-visible="showConfirmationRelease"
							:text="$t('alert.confirmRelease.text')"
							:position-higher="200"
						/>
						<!--edit peppol identifier ubl-->
					</div>
					<drop-down-menu
						:main-button-name="$t('buttons.download')"
						:width-px="widthMenuDownload"
						class="mr-2"
					>
						<template v-slot:options>
							<button style="padding: 5px; color: black"
									@click="downloadPdf(this.$route.name)">{{ $t('buttons.downloadPDF') }}
							</button>
							<button v-if="displayUBLButton" style="padding: 5px" @click="downloadUbl">
								{{ $t('buttons.downloadUbl') }}
							</button>
							<button style="padding: 5px"
									@click="downloadPDFset(this.$route.name)">{{ $t('buttons.downloadSet') }}
							</button>
						</template>
					</drop-down-menu>

					<button v-if="showDeleteButton" class="btn custom-button shadow-md no-break-text"
							@click="checkDelete">
						{{ $t('buttons.delete') }}
					</button>
				</div>
			</div>
		</div>

		<resend-document
			v-if="this.$route.name !== 'public'"
			:docId="[docId]"
			:legalEntityId="legalEntityId"
			:docsData="[{docId: docId, legalEntityId: legalEntityId, documentData: this.$store.state.documentDetailData, ublValid: ublValid}]"
			:show-resend-document="showResendDocument"
			:docs-to-resend="[this.$store.state.documentDetailData]"
			@close-pop-up="closeResend(true)"
			@close-pop-up-cancel="closeResend(false)"
		/>
		<!--Do you want to handle failed document after resend-->
		<confirmation-with-props
			:description="$t('confirmation.description.confirmSolveIssueAfterResend')"
			:title="'confirmation.title.titleSolveIssueAfterResend'"
			:show-dialog="showSolveIssueAfterResend"
			@cancel-action="showSolveIssueAfterResend = false"
			@approve-action="handleErrors"
		></confirmation-with-props>

		<ask-pop-up/>
		<handle-error/>
	</div>
</template>


<script>

import {defineComponent} from "vue";
import ResendDocument from "../resend/ResendDocument.vue";
import AddAttachment from "../actions/AddAttachment.vue";
import AskPopUp from "../../UI/AskPopUp.vue";
import PopUpWindow from "../../UI/PopUpWindow.vue";
import AlertNew from "../../UI/AlertNew.vue";
import ConfirmationWithProps from "../../UI/ConfirmationWithProps.vue";
import routerData from "../../../routerData.js";
import i18n from "../../../i18n.js";
import userGroups from "../../../userGoups.js";
import DropDownMenu from "../../UI/DropDownMenu.vue"
import HandleError from "../actions/HandleError.vue";

export default defineComponent({
	name: "ActionButtons",
	components: {
		AlertNew,
		HandleError,
		PopUpWindow,
		AskPopUp,
		AddAttachment,
		ResendDocument,
		DropDownMenu,
		ConfirmationWithProps
	},
	props: {
		accessCode: String,
		docName: String,
		docNumber: String,
		legalEntityId: String,
		docId: String,
		ublValid: Boolean,
		visibility: String,
		dueDate: String,
		docType: String,
		ublFile: Boolean,
		errorsAtDocument: Boolean,
		payment: String,
		channels: Object
	},
	data() {
		return {
			routerData: routerData,
			downloadFile: {label: i18n.t('buttons.downloadOriginals'), value: ''},
			showResendDocument: false,
			showPopupPayment: false,
			showConfirmationChangedPayment: false,
			showPopupVisibility: false,
			showConfirmationChangedVisibility: false,
			showAddAttachment: false,
			showConfirmationRelease: false,
			showRelease: false,
			showDeleteWindow: false,
			showSolveIssueAfterResend: false,
			documentData: this.$store.getters.getDocumentDetailData,
			isInvoicePaid: false,
			isDocumentInvoiceVisible: false,
			displayTooltipNoChannelsAreSet: false
		}
	},
	mounted() {
		this.isInvoicePaid = this.payment.includes("payment-icon-green")
		this.isDocumentInvoiceVisible = this.documentData.visible
	},
	watch: {
		downloadFile(newVal) {
			if (this.downloadFile.value !== '') {
				if (newVal.value === 'xml') {
					window.open(this.originalXml)
				}
				if (newVal.value === 'pdf') {
					window.open(this.originalPdf)
				}
				if (newVal.value === 'json') {
					this.downloadJson()
				}
				this.downloadFile = {label: i18n.t('buttons.downloadOriginals'), value: ''}
			}
		},
		"$store.state.getters.getLocale": {
			handler() {
				this.setTranslations()
			}
		},
		"$store.state.documentDetailData": {
			handler() {
				this.documentData = this.$store.state.documentDetailData
				const payment = this.$store.state.documentDetailData.channels.payment?.paymentUpdates?.find(payment => payment.paymentStatus === "PAYMENT_CONFIRMED")
				this.isInvoicePaid = !!payment;
			}
		},
		"$store.state.documentDetailData.visible": {
			handler() {
				this.isDocumentInvoiceVisible = this.$store.state.documentDetailData.visible
			}
		},
	},
	computed: {
		isPublicPage() {
			return this.$route.name === 'public';
		},
		displayDownloadOriginals() {
			return (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)) && this.$route.name === 'documentDetail';
		},
		downloadFileTypeOptions() {
			let fileTypes = [
				{label: i18n.t('buttons.downloadOriginals'), value: ''},
				{label: "XML", value: 'xml'},
				{label: "PDF", value: 'pdf'},
			]

			if (this.$store.state.documentDetailData.fileStructure.jsonFileName) fileTypes.push({
				label: 'JSON',
				value: 'json'
			})

			return fileTypes
		},
		originalXml() {
			return 'https://' + this.$store.state.documentDetailData.fileStructure.bucketName + '.storage.googleapis.com/' + this.$store.state.documentDetailData.fileStructure.opDocumentXmlFileName
		},
		originalPdf() {
			return 'https://' + this.$store.state.documentDetailData.fileStructure.bucketName + '.storage.googleapis.com/' + this.$store.state.documentDetailData.fileStructure.opDocumentOriginalFileName
		},
		showResendButton() {
			return !(this.$store.state.currentUserData.data.group === "ReceiverAdmin"
				|| this.$store.state.currentUserData.data.group === "ReceiverUser"
				|| this.$store.state.currentUserData.data.group === "SupplierLimitedSubUser"
				|| this.$route.name === 'public'
				|| this.visibility.includes("icon-eye-blocked"));
		},
		disableResendButton() {
			if (this.$store.state.documentDetailData.held === true) return true

			let allChannelsDisabledLe = true

			// Docs of one le are selected, it's not possible to resend (all channels false)
			if (this.$store.state.supplierGroup.data.supplierLegalEntities[this.$store.state.documentDetailData.legalEntity.entityNumber].channelsConfig !== undefined) {
				Object.values(this.$store.state.supplierGroup.data.supplierLegalEntities[this.$store.state.documentDetailData.legalEntity.entityNumber].channelsConfig).forEach(element => {
					if (element.enabled === true) {
						allChannelsDisabledLe = false
					}
				})
				if (allChannelsDisabledLe) {
					this.setTooltipAllChannelsDisabled()
					return true
				}
			}

			const today = new Date()
			today.setHours(0)
			today.setMinutes(0)
			today.setSeconds(0)
			today.setMilliseconds(0)

			let validityDate = new Date(this.dueDate)
			validityDate.setHours(0)
			validityDate.setMinutes(0)
			validityDate.setSeconds(0)
			validityDate.setMilliseconds(0)

			if (this.docType === "Onboarding") {
				if (today.valueOf() > validityDate.valueOf()) return true
			}
			return false
		},
		displayUBLButton() {
			return this.ublFile
		},
		widthMenuDownload() {
			if (this.displayUBLButton) {
				return 115
			} else {
				return 120
			}
		},
		widthMenuActions() {
			if (this.displayHandleErrors || this.showAddAttachmentButton) {
				return 160
			} else {
				return 100
			}
		},
		showDeleteButton() {
			if (this.$route.name === 'public') {
				return false
			}
			return (this.$store.state.currentUserData.data.group === "OpHelpdesk") || (this.$store.state.currentUserData.data.group === 'OpAdmin');
		},
		paidButtonText() {
			if (this.isInvoicePaid === true) {
				return i18n.t('buttons.setUnPaid')
			} else {
				return i18n.t('buttons.setPaid')
			}
		},
		toggleVisibilityText() {
			if (this.isDocumentInvoiceVisible === true) {
				return i18n.t('buttons.setVisibilityOff')
			} else {
				return i18n.t('buttons.setVisibilityOn')
			}
		},
		descriptionConfirmDialogPaymentStatus() {
			if (this.isInvoicePaid === true) {
				return i18n.t('confirmation.description.confirmSetUnPaid')
			} else return i18n.t('confirmation.description.confirmSetPaid')
		},
		descriptionConfirmDialogVisibility() {
			if (this.isDocumentInvoiceVisible === true) {
				return i18n.t('confirmation.description.confirmSetVisibilityOff')
			} else return i18n.t('confirmation.description.confirmSetVisibilityOn')
		},
		descriptionConfirmDialogRelease() {
			return i18n.t('confirmation.description.confirmReleaseDocument')
		},
		warningRelease() {
			if (this.$store.state.documentDetailData.held
				&& this.$store.state.documentDetailData.fileStructure.attachmentsAdded
				&& (this.$store.state.documentDetailData.channels.attachEmail?.merge === true
					|| this.$store.state.documentDetailData.channels.linkEmail?.merge === true
					|| this.$store.state.documentDetailData.edi?.merge === true)) {
				return '\n' + i18n.t('confirmation.description.confirmReleaseDocumentMergeAttachments')
			} else return null
		},
		showPaidButton() {
			if (this.$route.name === 'public') return false
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.showPaymentStatus === false) return false

			if (this.$store.state.documentDetailData.documentType.toLowerCase() === "invoice" || this.$store.state.documentDetailData.documentType.toLowerCase() === "creditnote") {
				return this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin'
					|| this.$store.state.currentUserData.data.group === 'OpHelpdesk'
					|| this.$store.state.currentUserData.data.group === 'OpAdmin'
			} else return false
		},
		displayHandleErrors() {
			if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| (userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) && this.$store.state.currentUserData.data.group !== 'SupplierLimitedSubUser')) {
				if ((this.errorsAtDocument === true) && (!this.$store.state.documentDetailData.channels.archive.errorsHandled)) {
					return true
				}
			}
			return false
		},

		showVisibility() {
			if (this.$route.name === 'public') return false
			if (!this.$store.state.documentDetailData.channels?.attachEmail
				&& !this.$store.state.documentDetailData.channels?.linkEmail
				&& !this.$store.state.documentDetailData.channels?.edi
				&& !this.$store.state.documentDetailData.channels?.print) {
				return this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin'
					|| this.$store.state.currentUserData.data.group === 'OpHelpdesk'
					|| this.$store.state.currentUserData.data.group === 'OpAdmin'
			} else return false
		},

		showAddAttachmentButton() {
			return !!(userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group) || userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group));
		},
		showHoldRelease() {
			if (this.$store.state.supplierGroup.data.holdRelease !== undefined && this.$store.state.documentDetailData.held !== undefined) {
				if (this.$store.state.supplierGroup.data.holdRelease === true && this.$store.state.documentDetailData.held) {
					return this.$store.state.currentUserData.data.group !== 'ReceiverAdmin'
						&& this.$store.state.currentUserData.data.group !== 'ReceiverUser'
				}
			}
			return false
		},
	},
	methods: {
		setTranslations() {
			if (this.downloadFile.value === '') {
				this.downloadFile.label = i18n.t('buttons.downloadOriginals')
			}
		},
		downloadJson() {
			this.$store.dispatch('documents/downloadJson', {
				docId: this.docId,
				docName: this.docNumber
			})
		},
		setTooltipAllChannelsDisabled(){
			this.displayTooltipNoChannelsAreSet = true
		},
		showResendDocumentWindow() {
			this.$store.commit("displayAskPopUp", {display: false, action: ''})
			this.$store.dispatch("updateToken")
			this.$store.commit('displayResendDocument', true)
			this.showResendDocument = true
		},
		handleErrors() {
			if (this.showSolveIssueAfterResend === true) this.showSolveIssueAfterResend = false
			this.$store.dispatch('updateToken');
			this.$store.commit("setDisplayHandleError", true)
		},
		setPaymentStatus() {
			const documents = []
			documents.push({
				legalEntityId: this.$store.state.documentDetailData.legalEntity.entityNumber,
				docId: this.docId,
				documentNumber: this.docNumber
			})
			const payload = {
				documents: documents
			}
			if (this.isInvoicePaid) {
				this.$store.dispatch("documents/setInvoiceUnpaid", payload)
			} else {
				this.$store.dispatch("documents/setInvoicePaid", payload)
			}
			this.isInvoicePaid = !this.isInvoicePaid
			this.showPopupPayment = false
			this.showConfirmationChangedPayment = true

			setTimeout(() => {
				this.showConfirmationChangedPayment = false
			}, 3000)
		},
		async toggleVisibility() {
			const currentVisibility = this.visibility
			let docId = []
			let setVisibility

			docId.push(this.docId)
			if (currentVisibility && currentVisibility.includes("icon-eye-blocked")) {
				setVisibility = true
			} else if (currentVisibility) {
				setVisibility = false
			}

			const payload = {
				docIds: docId,
				visibility: setVisibility
			}

			try {
				await this.$store.dispatch("documents/toggleVisibility", payload)
				this.$store.state.documentDetailData.visible = !this.$store.state.documentDetailData.visible

				this.showPopupVisibility = false
				this.showConfirmationChangedVisibility = true
				setTimeout(() => {
					this.showConfirmationChangedVisibility = false
				}, 3000)
			} catch (err) {
				this.titleAlert = "visibility didn't changed"
				this.textAlert = "something went wrong"

				setTimeout(() => {
					this.titleAlert = null
					this.textAlert = null
				}, 5000)
			}
		},
		showReleaseConfirmAlert() {
			this.showAddAttachment = false
			this.showConfirmationRelease = true
			setTimeout(() => {
				this.showConfirmationRelease = false
			}, 3000)
		},
		async releaseDocument() {
			try {
				let data = await this.$store.dispatch('documents/releaseDocument', {docId: this.docId})
				this.showRelease = false
				if (data.status === 200) {
					this.showConfirmationRelease = true
					this.$store.state.documentDetailData.held = false
					this.documentData.held = false
					setTimeout(() => {
						this.showConfirmationRelease = false
					}, 3000)
				}
			} catch (err) {
				console.error(err)
				this.$store.state.errorNotification.display = true
			}

		},
		downloadUbl() {
			this.$store.dispatch("documents/downloadUbl", {ids: this.docId, number: this.docNumber})
		},

		downloadPdf(route) {
			this.$store.dispatch("documents/downloadPdf", {
				ids: this.docId,
				docName: this.docName,
				route: route,
				accessCode: this.accessCode
			})
		},
		downloadPDFset(route) {
			this.$store.dispatch("documents/downloadDocumentSet", {
					ids: this.docId,
					route: route,
					accessCode: this.accessCode,
					docNumber: this.docNumber
				},
			)
		},
		checkDelete() {
			this.$store.dispatch("updateToken")
			this.showDeleteWindow = true
		},
		cancelDelete() {
			this.showDeleteWindow = false
		},
		deleteDocument() {
			this.$store.dispatch('documents/deleteDocument', {legalEntityId: this.legalEntityId, docId: this.docId})
		},
		closeResend(isResend) {
			this.showResendDocument = false
			if (isResend && this.displayHandleErrors === true) {
				this.showSolveIssueAfterResend = true
			}
		},

	}
})
</script>

<style scoped>
.btn-content {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: column;
	justify-items: center;
}

.button-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.button-container-without-dropdown {
	display: flex;
	flex-direction: row;
	justify-content: right;
}

.btn:disabled:hover .tooltip {
	display: block;
}

.tooltip {
	position: absolute;
	display: none;
	margin-top: 30px;
	padding: 1px;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	font-size: 12px;
}

.download-original {
	width: 250px;
}

#goBack {
	cursor: pointer;
	font-size: 20px;
}

#goBack:after {
	content: ' \003C \003C';
}

.button-container-outer {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 40px auto;
	grid-auto-flow: row;
	align-items: center;
}

.displayNone {
	visibility: hidden;
}

.no-break-text {
	white-space: nowrap;
}

.buttons-left {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 40px auto;
	grid-auto-flow: row;
	align-items: center;
}

.download-buttons-public-page {
	display: flex;
	justify-content: end;
}

.buttons-right {
	display: flex;
	flex-direction: row;
}

@media only screen and (min-width: 0px) and (max-width: 750px) {
	.button-container {
		display: flex;
		flex-direction: column;
	}

	.button-container-without-dropdown {
		display: flex;
		flex-direction: column;
	}

	.download-original {
		width: 250px;
		margin-bottom: 15px;
	}
}
</style>

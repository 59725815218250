<template>
	<div>
		<!-- BEGIN: HTML Table Data -->
		<div class="" id="tabulatorOuter">
			<div class="filter-container">
				<button
					:disabled="!selectedDocuments.length"
					class="btn custom-button shadow-md handle-error mr-1.5"
					@click="startPrint"
				>
					<span class="button-text">{{ $t('buttons.startPrint') }}</span>
					<span class="tooltip">{{ $t('tooltips.selectBatch') }}</span>
				</button>
				<extended-search-filter
					current-page="uploadedFiles"
					class="mr-1.5"
					@search="onFilter"
					@reset-filter="onFilter"
				/>
				<button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
						@click="cleanExtendedSearchValues">
					{{ $t('buttons.reset') }}
				</button>
			</div>
			<filter-label @remove-label="onFilter"/>
			<div class="doc-info-margin">
				<div class="totalNumberDocuments">
					{{ $t('table.totalNumberPendingBatches') }}: {{ totalDocuments }}
				</div>
				<div class="totalNumberDocuments">
					{{ $t('table.totalNumberPendingFiles') }}: {{ totalFiles }}
				</div>
			</div>

			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				/>
			</div>
		</div>
		<!-- END: HTML Table Data -->
		<view-files/>
		<delete-batch/>
	</div>
</template>

<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'
import i18n from "../../../i18n"
import cash from "cash-dom"
import en from "../../../locales/en.json"
import nl from "../../../locales/nl.json"
import de from "../../../locales/de.json"
import fr from "../../../locales/fr.json"
import sk from "../../../locales/sk.json"
import es from "../../../locales/es.json"
import ViewFiles from "./ViewFiles"
import DeleteBatch from "./DeleteBatch"
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue";
import FilterLabel from "@/components/extendedSearch/FilterLabel.vue";

export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, DeleteBatch, ViewFiles},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup() {
		const store = useStore()

		const searchTableValue = ref(store.state.postDropTableSearchValue)
		const tableRef = ref()
		const tabulator = ref()
		const pageSize = ref(10)
		const filter = reactive({
			value: searchTableValue
		})
		let selectedDocuments = ref([])
		let totalDocuments = ref(store.state.postDrop.pendingTable.data.length)
		const tableIsLoading = ref(false)

		const totalFiles = computed(() => {
			let fileNumber = 0
			store.state.postDrop.pendingTable.data.forEach((item) => {
				fileNumber = fileNumber + item.fileName.length
			})

			return fileNumber
		})

		let tableColumns = computed(() => {
			return [
				{
					title: '<input class ="customCheckbox ml-4" type=checkbox id="selectAllDocuments">',
					field: 'docIds',
					headerClick: function (e) {
						selectAll(e)
					},
					width: 60,
					headerSort: false,
					formatter(cell) {
						if (cell.getData().batchId !== '-') {
							const a = cash(
								`<div>
                                          	<input type=checkbox
                                            	value=${cell.getData().batchId}
                                            	class="downloadCheckbox common customCheckbox ml-4 checkBoxStart"
                                          	/>
                          				</div>`)
							cash(a).on('change', function () {
								let selectAllEl = document.getElementById("selectAllDocuments")
								selectAllEl.checked = false
								let index = selectedDocuments.value.indexOf(cell.getData().batchId)

								if (index === -1) {
									selectedDocuments.value.push(cell.getData().batchId)
								}
								if (index > -1) {
									selectedDocuments.value.splice(index, 1)
								}
							})
							return a[0]
						} else {
							const a = cash(
								`<div>
                                         	<input
                                         		type=checkbox
                                            	value=${cell.getData().batchId}
                                            	class="downloadCheckbox common customCheckbox ml-4 checkBoxStart"
                                            	:disabled="true"
                                          	/>
                          				</div>`)
							return a[0]
						}
					}
				},
				{
					title: 'DEPARTMENT NAME',
					field: 'departmentName',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ">
                                     	<div>${cell.getData().department}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'BATCH ID',
					field: 'batchId',
					cssClass: "text-centered",
					headerSort: false,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().batchId}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'POSTAL TYPE',
					field: 'postalType',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div>${cell.getData().postalType}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'PRINT MODE',
					field: 'printMode',
					cssClass: "text-centered",
					headerSort: true,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div>${cell.getData().printMode}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'FILE NAME',
					field: 'fileName',
					cssClass: "text-centered",
					headerSort: false,
					minWidth: '150',
					formatter(cell) {
						if (typeof cell.getData().fileName !== "object") {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 grid-columns-1 justify-items-center">
                                         	<div class="files-preview justify-items-end">${cell.getData().fileName} </div>
                                         </div>`)
							return a[0]
						} else {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 grid-columns-1 justify-items-center">
                                         	<div class="files-preview justify-items-end">${cell.getData().fileName[0]}, ... </div>
                                         </div>`)
							return a[0]
						}
					}
				},
				{
					title: 'VIEW FILES',
					field: 'viewFiles',
					width: "80",
					cssClass: "text-centered color-grey",
					headerSort: false,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                     	<div class="tooltip-outer">
                                        	<i class="icon-eye"></i>
                                            <span class="tooltip-inner view-files">${cell.getData().viewFilesTooltip}</span>
                                        </div>
                                     </div>`)
						cash(a).on('click', function () {
							store.commit("setViewFilesData", cell.getData().fileName)
							store.commit('displayViewFiles', true)
						})
						return a[0]
					}
				},
				{
					title: 'DELETE BATCH',
					field: 'deleteBatch',
					width: "80",
					cssClass: "text-centered color-grey",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().batchId !== '-') {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                         	<div class="tooltip-outer">
                                            	<i class="icon-bin"></i>
                                                <span class="tooltip-inner delete-batch">${cell.getData().deleteBatchTooltip}</span>
                                            </div>
                                    	</div> `)
							cash(a).on('click', function () {
								store.dispatch("updateToken")
								store.commit("setDeleteBatchData", cell.getData().batchId)
								store.commit('displayDeleteBatch', true)
							})
							return a[0]
						} else {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                         	<div class="tooltip-outer">
                                            	<i class="icon-bin icon-color-disable"></i>
                                                <span class="tooltip-inner disabled-delete-batch">${cell.getData().disabledDeleteBatchTooltip}</span>
                                            </div>
                                    	 </div>`)
							return a[0]
						}
					}
				},
				{
					title: 'START PRINT',
					field: 'startPrint',
					width: "80",
					cssClass: "text-centered color-grey",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().batchId !== '-') {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                         	<div class="tooltip-outer">
                                            	<i class="icon-spinner11"></i>
                                                <span class="tooltip-inner start-print">${cell.getData().startPrintTooltip}</span>
                                            </div>
                                    	 </div> `)
							cash(a).on('click', async function () {
								await store.dispatch("updateToken")
								await store.dispatch('startPrint', [cell.getData().batchId])
							})
							return a[0]
						} else {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                         	<div class="tooltip-outer">
                                            	<i class="icon-spinner11 icon-color-disable"></i>
                                                <span class="tooltip-inner disabled-start-print">${cell.getData().disabledStartPrintTooltip}</span>
                                            </div>
                                    	</div>`)
							return a[0]
						}
					}
				}
			]
		})

		watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator()
					store.commit("departmentsUpdated", false)
				}, 1000)
			}
		})
		watch(() => store.getters.getLocale, function () {
			store.state.postDrop.pendingBatches.ids[0].label = i18n.t('dropdowns.postDrop.batch')
			tabulator.value.setLocale(store.getters.getLocale)
		})
		watch(() => store.state.postDrop.pendingTable.data, () => {
			if (store.state.postDrop.pendingTable.isReady) {
				totalDocuments.value = store.state.postDrop.pendingTable.data.length
				initTabulator()
			}
		})

		const selectAll = (e) => {
			let cells = document.getElementsByClassName("checkBoxStart")
			let batchIds = []
			for (const cell of cells) {
				if (cell.value !== '-') {
					cell.checked = e.target.checked
					batchIds.push(cell.value)
				}
			}
			if (e.target.checked) {
				let selectedBatches = JSON.parse(JSON.stringify(batchIds))
				const index = selectedBatches.indexOf('-')
				if (index > -1) {
					selectedBatches.splice(index, 1)
				}
				selectedDocuments.value = [...new Set(selectedBatches)]
			} else {
				selectedDocuments.value = []
			}
		}
		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value)
			tabulator.value = new Tabulator(tableRef.value, {
				data: store.state.postDrop.pendingTable.data,
				headerSortElement: "<span><i claas='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: false,
				printAsHtml: false,
				printStyled: false,
				pagination: 'local',
				paginationSize: pageSize.value,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				columnDefaults: {
					resizable: false,
				},
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const startPrint = async () => {
			await store.dispatch('startPrint', selectedDocuments.value)

			document.getElementById('selectAllDocuments').checked = false

			selectedDocuments.value = []
			let allCheckboxes = document.getElementsByClassName("checkBoxStart")
			Object.values(allCheckboxes).forEach(checkBox => checkBox.checked = false)
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw()
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
		const onFilter = async () => {
			await store.dispatch("updateToken")

			await store.dispatch("setPendingTableData", {
				batch: store.state.extendedSearchValue.extendedSearch.batch?.value
					? store.state.extendedSearchValue.extendedSearch.batch?.value : '' ,
				department: store.state.extendedSearchValue.extendedSearch.departmentCode?.value
					? store.state.extendedSearchValue.extendedSearch.departmentCode?.value : ''
			})

			tabulator.value.destroy()
			initTabulator()
		}

		const cleanExtendedSearch = () => {
			store.state.extendedSearchValue.extendedSearch = {}
			onFilter()
		}
		const cleanExtendedSearchValues = () => {
			cleanExtendedSearch()
			onFilter()
		}

		onMounted(() => {
			initTabulator()
			reInitOnResizeWindow()
		})

		return {
			tableRef,
			filter,
			onFilter,
			totalDocuments,
			totalFiles,
			tabulator,
			cleanExtendedSearchValues,
			selectedDocuments,
			startPrint
		}
	}
})
</script>

<style scoped>
.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
}

.doc-info-margin {
	padding-top: var(--generalPaddings);
}

#selectAllDocuments {
	justify-self: center;
}

.buttons-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: repeat(2, auto);
	grid-auto-flow: column;
	grid-column-gap: var(--generalPaddings);
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.button-text {
	padding: 0.5rem 0.75rem;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.download-buttons .btn:disabled:hover .tooltip {
	display: block;
}

.extended-search {
	margin-top: 10px;
	width: 40%;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	grid-gap: 10px;
}

.extended-search-check-box {
	margin-top: 10px;
	line-height: 1.5em;
}

.search-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.filter-container {
	width: 660px;
	display: flex;
	flex-direction: row;
}

.extended-search {
	margin-top: 10px;
	width: 640px;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	grid-gap: 10px;
	margin-right: 10px;
	position: relative;
	top: -5px;
}

.handle-error {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	padding: 0;
}

.handle-error span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.handle-error:disabled:hover .tooltip {
	display: block;
}

.tooltip {
	display: none;
	position: relative;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	font-size: 12px;
}

.buttons-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
	align-self: center;
	justify-self: start;
}

@media only screen and (max-width: 750px) {
	.filter-container {
		width: 90%;
	}

	.extended-search {
		width: 100%;
	}
}

@media only screen and (max-width: 520px) {
	.extended-search {
		grid-template-columns: 100%;
	}
}
</style>